'use strict';
import {ArrowPathIcon} from '@heroicons/react/20/solid';
import * as Dialog from '@radix-ui/react-dialog';
import cls from 'classnames';
import React, {createRef, FormEvent, InputHTMLAttributes, useEffect, useState} from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import {getMoreInfoOptions, submitMessageRequest} from '../Services/propertyManagerForm';
import {MoreInfoSelectOptions} from '../Types';
import {SocialLinks} from './SocialLinks';

//change outline
const fieldClasses = 'border outline-none border-brand-light-blue focus:ring-brand-blue focus:border-transparent focus:ring-2 sm:px-6 px-3 sm:py-4 py-2 placeholder:text-brand-blue placeholder:font-semibold sm:text-base text-sm text-brand-blue font-semibold';

const Input = ({field, setField, placeholder, disabled, ...props}
  : InputHTMLAttributes<HTMLInputElement> & {
  field: string,
  setField: React.Dispatch<React.SetStateAction<string>>,
  placeholder: string
}) => (
  <input {...props} className={cls(fieldClasses, 'rounded-button')} value={field} onChange={e => setField(e.target.value)} placeholder={placeholder}/>
);

interface EnquireFormProps {
  className?: string,
  contextID?: string | number,
  nameField?: string,
  buttonTitle?: string,
  phoneField?: string,
  emailField?: string,
  linkedIn?: string,
  facebook?: string
  title?: string
  description?: string
  siteKey: string
  route?: string
  showMoreInfo?: boolean
}

export const EnquireForm = (
  {
    className,
    contextID,
    nameField,
    buttonTitle,
    phoneField,
    emailField,
    linkedIn,
    facebook,
    title,
    description,
    route = '/property-manager/message',
    siteKey,
    showMoreInfo = true,
  }: EnquireFormProps) => {
  const [open, setOpen] = useState(false);
  const [thankYou, setThankYou] = useState(false);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [name, setName] = useState<string>('');
  const [email, setEmail] = useState<string>('');
  const [phone, setPhone] = useState<string>('');
  const [moreInfo, setMoreInfo] = useState<string>('');
  const [moreInfoOptions, setMoreInfoOptions] = useState<MoreInfoSelectOptions[] | []>([]);
  const [message, setMessage] = useState<string>('');
  const [error, setError] = useState<string>('');
  const [isRecaptchaSet, setIsRecaptchaSet] = useState(false);

  const recaptchaRef = createRef();
  const onChange = () => {
    setIsRecaptchaSet(true);
  };

  useEffect(() => {
    const {request, abortController} = getMoreInfoOptions();

    request.then((data) => {
      if (!abortController?.signal.aborted) {
        let newOptions = Object.entries(data).map(([key, value]) => ({value: key, label: value}));
        // @ts-ignore
        setMoreInfoOptions(newOptions);
      }
    });
    return () => abortController?.abort();
  }, []);
  const submitForm = async (e: FormEvent) => {
    e.preventDefault();
    try {
      setSubmitting(true);
      const {request, abortController} = submitMessageRequest({
        name: name,
        email: email,
        phone: phone,
        moreInfo: moreInfo,
        message: message,
        contextID: contextID,
        recaptcha: recaptchaRef.current.getValue(),
        route: route,
      });
      request.then((data) => {
        setSubmitting(false);
        if (data.ok) {
          setThankYou(true);
        } else {
          const reader = data.body.getReader();
          reader.read().then(({value}) => {
            setError(new TextDecoder('utf-8').decode(value));
          });
        }
      });


      return () => abortController?.abort();
    } catch (e) {
      setError('Something went wrong. Please try again later');
      setSubmitting(false);
    }
  };

  const closeClick = () => {
    setOpen(false);
    setThankYou(false);
    setName('');
    setEmail('');
    setPhone('');
    setMoreInfo('');
    setMessage('');
    setError('');
    setIsRecaptchaSet(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <button className={className}>
          {buttonTitle}
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black opacity-70 data-[state=open]:animate-overlayShow fixed z-50 inset-0"/>

        <Dialog.Content
          className="data-[state=open]:animate-contentShow sm:h-auto h-screen fixed z-50 top-[50%] left-[50%] md:w-auto w-[95%] translate-x-[-50%] translate-y-[-50%] rounded-[6px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none">
          <div className="bg-white rounded-select sm:px-12 px-5 sm:w-128 py-15 relative max-h-screen overflow-auto">
            {!thankYou ? (
                <form onSubmit={submitForm}>
                  <div className="mx-auto">
                    <svg className="mx-auto mb-4" width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="12.8127" y="28" width="20.5912" height="3.08868" rx="1.54434" transform="rotate(-128.48 12.8127 28)" fill="#005395"/>
                      <rect x="13.2129" y="18.0412" width="20.5912" height="3.08868" rx="1.54434" transform="rotate(-128.48 13.2129 18.0412)"
                            fill="#EF3C43"/>
                    </svg>
                    <h2 className="sm:text-4xl text-2.5xl text-brand-blue text-center leading-slim ">
                      {title ? title : 'Hello, nice to meet you.'}
                    </h2>
                  </div>
                  <p className="text-brand-blue sm:text-base text-xs text-center mt-4 sm:mb-12 mb-4">
                    {
                      description ? description :
                        'Simply fill out the form below and I will get back to you within 1 working day.'
                    }
                  </p>
                  <div className="flex flex-col sm:gap-y-5 gap-y-2">
                    <Input type="text" field={name} setField={setName} placeholder="Name" disabled={submitting} required autoComplete="name"/>
                    <Input type="email" field={email} setField={setEmail} placeholder="Email" disabled={submitting} required autoComplete="email"/>
                    <Input field={phone} setField={setPhone} placeholder="Phone" disabled={submitting} required autoComplete="phone"/>
                    {showMoreInfo &&
                      <select className={cls(fieldClasses, 'rounded-button')} value={moreInfo} onChange={(e) => setMoreInfo(e.target.value)}
                              disabled={submitting} required>
                        <option value="">I want to know more about Please select</option>
                        {moreInfoOptions.map((option) => (
                          <option key={option.value} value={option.value}>
                            {option.label}
                          </option>
                        ))}
                      </select>}
                    <textarea className={cls(fieldClasses, 'h-48 rounded-select resize: none')} value={message}
                              onChange={e => setMessage(e.target.value)} placeholder="Do you want to leave a message? (optional)"/>
                    <ReCAPTCHA
                      className="my-6"
                      ref={recaptchaRef}
                      sitekey={siteKey}
                      type="image"
                      onChange={onChange}
                    />

                    {
                      error &&
                      <div className="relative h-32">
                        <div className="absolute -bottom-6">
                          <div className="bg-brand-red z-20 rounded-r-box sm:pl-9 sm:pb-10 sm:pt-9 sm:pr-14 sm:pl:5 pb-4 pt-4 pr-7 pl-4 ">
                            <h3 className="text-white sm:text-2xl text-lg tracking-slim">{error}</h3>
                          </div>
                          <div className="bg-brand-blue w-2.5 h-full rounded-l-box z-20 absolute -left-2.5 top-0">
                          </div>
                        </div>
                      </div>
                    }
                    <button type="submit" className="disabled:opacity-75 filled-red-button text-center text-white block border-2 border-brand-red"
                            disabled={submitting || !isRecaptchaSet}>
                      {(submitting) && <ArrowPathIcon className="w-5 h-5 inline-block mr-2 animate-spin"/>}
                      Send
                    </button>
                  </div>
                </form>
              )
              :
              (
                <>
                  <div className="mx-auto">
                    <svg className="mx-auto mb-4" width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
                      <rect x="12.8127" y="28" width="20.5912" height="3.08868" rx="1.54434" transform="rotate(-128.48 12.8127 28)" fill="#005395"/>
                      <rect x="13.2129" y="18.0412" width="20.5912" height="3.08868" rx="1.54434" transform="rotate(-128.48 13.2129 18.0412)"
                            fill="#EF3C43"/>
                    </svg>
                    <h2 className="text-4xl text-brand-blue text-center leading-slim ">
                      Thanks for getting in touch.
                    </h2>
                  </div>
                  <div className="text-brand-blue text-center ">
                    <p className="mt-4 mb-12">
                      If you have any questions in the meantime please don’t hesitate to contact me.
                    </p>
                    <div className="mt-11 space-y-5 mb-9">
                      {nameField && <h4 className="text-2.5xl">{nameField}</h4>}
                      {
                        phoneField && <>
                          <p className="font-semibold">Mobile</p>
                          <a href={'tel:' + phoneField}>{phoneField}</a>
                        </>
                      }
                      {
                        emailField && <>
                          <p className="font-semibold">Email</p>
                          <a href={'mailto:' + emailField}>{emailField}</a>
                        </>
                      }
                    </div>
                    <SocialLinks linkedIn={linkedIn} facebook={facebook} className="justify-center"/>
                  </div>
                  <div className="flex flex-col mt-8">
                    <button type="button" className="disabled:opacity-75 filled-red-button text-center text-white block border-2 border-brand-red"
                            onClick={closeClick}>
                      Close
                    </button>
                  </div>
                </>
              )}

            {!submitting && (
              <button
                className="text-white ease-in-out transition duration-300 hover:text-brand-red absolute top-6 right-6 inline-flex w-auto appearance-none items-center justify-center rounded-full"
                aria-label="Close"
                onClick={closeClick}
              >
                <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
                  <circle cx="20" cy="20" r="14" fill="white" stroke="#005395" strokeWidth="2"/>
                  <path
                    d="M23.364 16.2933C23.7545 16.6838 23.7545 17.317 23.364 17.7075L20.5356 20.5359C20.145 20.9265 19.5119 20.9265 19.1213 20.5359C18.7308 20.1454 18.7308 19.5123 19.1213 19.1217L21.9498 16.2933C22.3403 15.9028 22.9735 15.9028 23.364 16.2933Z"
                    fill="#005395"/>
                  <path
                    d="M20.5356 20.5359C20.145 20.9265 19.5119 20.9265 19.1213 20.5359L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L20.5356 19.1213C20.9261 19.5118 20.9261 20.1454 20.5356 20.5359Z"
                    fill="#005395"/>
                  <path
                    d="M16.2932 23.5355C15.9026 23.145 15.9026 22.5118 16.2932 22.1213L19.1216 19.2929C19.5121 18.9024 20.1453 18.9024 20.5358 19.2929C20.9264 19.6834 20.9264 20.3166 20.5358 20.7071L17.7074 23.5355C17.3169 23.9261 16.6837 23.9261 16.2932 23.5355Z"
                    fill="#005395"/>
                  <path
                    d="M19.1216 19.2929C19.5121 18.9024 20.1453 18.9024 20.5358 19.2929L23.3643 22.1218C23.7548 22.5123 23.7548 23.1454 23.3643 23.536C22.9738 23.9265 22.3406 23.9265 21.9501 23.536L19.1216 20.7076C18.7311 20.317 18.7311 19.6834 19.1216 19.2929Z"
                    fill="#005395"/>
                </svg>
              </button>
            )}
          </div>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
