import {XMarkIcon} from '@heroicons/react/20/solid';
import * as Dialog from '@radix-ui/react-dialog';
import cls from 'classnames';
import React, {useRef, useState} from 'react';

function VideoModal({children, src, youtube}: { children: React.ReactNode, src: string, youtube?: boolean }) {
  const video = useRef(null);
  const [open, setOpen] = useState(false);
  const videoEnded = () => {
    setOpen(false);
  };

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        {children}
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black opacity-70 data-[state=open]:animate-overlayShow fixed z-50 inset-0"/>
        <Dialog.Content
          className={cls('w-[75%] data-[state=open]:animate-contentShow fixed z-50 top-[50%] left-[50%] h-auto translate-x-[-50%] translate-y-[-50%] rounded-[6px] shadow-[hsl(206_22%_7%_/_35%)_0px_10px_38px_-10px,_hsl(206_22%_7%_/_20%)_0px_10px_20px_-15px] focus:outline-none',
            youtube ? 'md:w-[75%]' : 'md:w-auto',
          )}>
          {
            youtube ? (
                <div className="embed">
                  <iframe width="560" height="315" src={src} title="YouTube video player" frameBorder="0" allowFullScreen
                          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture;">
                  </iframe>
                </div>
              )
              :
              (
                <video ref={video} className="w-auto h-auto" controls={true} onEnded={videoEnded}>
                  <source src={src} type="video/mp4"/>
                </video>
              )
          }

          <Dialog.Close asChild>
            <button
              className="text-white ease-in-out transition duration-300 hover:text-brand-red absolute top-0 -right-10 inline-flex w-auto appearance-none items-center justify-center rounded-full"
              aria-label="Close"
            >
              <XMarkIcon className="h-10 w-10" aria-hidden="true"/>
            </button>
          </Dialog.Close>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
}

export default VideoModal;
