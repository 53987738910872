'use strict';
import {Listbox, Transition} from '@headlessui/react';
import {XMarkIcon} from '@heroicons/react/20/solid';
import cls from 'classnames';
import React from 'react';

type Page = {
    name: string,
    link: string
}

const SelectButton = ({open}: {open: boolean}) => (
    <div className="h-5 w-7 my-auto z-30 relative burger-menu">
        <svg viewBox="0 0 28 22" fill="none" className={cls(open && 'nav-open')} xmlns="http://www.w3.org/2000/svg">
            <g id="Group 172">
                <line className="first-bar" x1="1" y1="1" x2="27" y2="1" strokeWidth="2" strokeLinecap="round"/>
                <line className="second-bar" x1="1" y1="11" x2="27" y2="11" strokeWidth="2" strokeLinecap="round"/>
                <line className="third-bar" x1="1" y1="21" x2="27" y2="21" strokeWidth="2" strokeLinecap="round"/>
            </g>
        </svg>
    </div>
);

export const MobileNav = ({pages, findARentalManagerLink}: { pages: Page[], findARentalManagerLink: string }) => (
    <Listbox>
        {({open}) => (
            <div className="relative">
                <Listbox.Button>
                    <SelectButton open={open}/>
                </Listbox.Button>
                <Transition as="div"
                            show={open}
                            enter="transform transition ease-in-out duration-300"
                            enterFrom="translate-x-full opacity-0"
                            enterTo="-translate-x-0 opacity-100"
                            leave="transform transition ease-in-out duration-300"
                            leaveFrom="-translate-x-0 opacity-100"
                            leaveTo="translate-x-full opacity-0"
                            className="absolute sm:-right-10 -right-5 -top-[34px] bg-brand-blue shadow-home z-10 border-transparent border-2 w-screen h-screen">
                    <Listbox.Options>
                        <div className="space-y-9 flex-col px-12 w-full pt-35 flex">
                            {pages.map((page: Page, index) => (
                                <a className="text-xl text-white transition duration-300 focus:text-brand-red" key={index} href={page.link}>
                                    {page.name}
                                </a>
                            ))}
                        </div>
                        <div className='px-12 w-full mt-12 pb-10'>
                            <a href={findARentalManagerLink} className="text-white transition focus:text-brand-red focus:border-brand-red w-full block text-center rounded-button border-2 px-x-button pt-top-button pb-4 border-white text-base font-semibold leading-tight hover:bg-brand-blue hover:text-white ease-in-out duration-150">
                                Find a rental manager
                            </a>
                        </div>
                    </Listbox.Options>
                </Transition>
            </div>
        )}
    </Listbox>
);

