import React, {ReactNode} from 'react';
import {FranchiseeEnquiry} from '../FranchiseeEnquiry';
import {Tag} from './Tag';

export type Franchise = {
  id: number,
  title: string,
  type: '' | 'PukekoProven' | 'PukekoPioneer',
  price: string,
  new: boolean,
  description: string,

  //Proven
  current_portfolio: string,
  annual_rent_collected: string,

  //Pioneer
  image: {
    src: string,
    title: string
  }
}
const TableRow = ({title, value, children}: { title: string, value?: string, children?: ReactNode }) => (
  <div className="grid grid-cols-2 gap-4 text-brand-blue py-4">
    <p className="xl:text-base text-sm">{title}</p>
    {
      children ? children : <p className="font-semibold xl:text-base text-sm">{value}</p>
    }
  </div>
);
export const Franchisee = ({franchisee, franchiseeContentID}: {
  franchisee: Franchise,
  franchiseeContentID?: string,
}) => {
  return (
    <div
      className="border border-brand-light-blue rounded-image w-full h-full sm:px-12 sm:py-12 py-12 px-6 flex flex-col justify-between relative">
      <div>

        {
          franchisee.image.src ?
            <img src={franchisee.image.src}
                 alt={franchisee.image.title} className="h-64 w-full rounded-[0.625rem]"/>
            :
            <img src="/assets/frontend/franchisee_placeholder.png"
                 alt="Franchisee Placeholder image" className="w-auto h-auto mx-auto rounded-[0.625rem]"/>
        }


        <h2 className="xl:text-4xl text-2.5xl text-brand-blue xl:mt-6 xl:mb-9 mb-4">{franchisee.title}</h2>
        <div className="divide-y divide-brand-light-blue border-brand-light-blue border-b">
          {(franchisee.description) &&
            <div className="text-brand-blue py-4">
              <p className="xl:text-base text-sm whitespace-pre-line">{franchisee.description}</p>
            </div>
          }
          <TableRow title="Franchise Type">
            <div className="flex flex-col">
              <p className="font-semibold xl:text-lg">{franchisee.type.split(/(Pukeko)/)[1]}<span
                className="text-brand-red">{franchisee.type.split(/(Pukeko)/)[2]}</span></p>
              {franchiseeContentID && <a href={window.location.pathname + '#' + franchiseeContentID} className="text-xs underline">More info</a>}
            </div>
          </TableRow>
          {(franchisee.type === 'PukekoProven' && franchisee.current_portfolio) &&
            <TableRow title="Current portfolio" value={franchisee.current_portfolio}/>}
          {(franchisee.type === 'PukekoProven' && franchisee.annual_rent_collected) &&
            <TableRow title="Annual rent collected" value={franchisee.annual_rent_collected}/>}
          {franchisee.price &&
            <TableRow title={franchisee.type === 'PukekoProven' ? 'Price (negotiation range)' : 'Price'} value={franchisee.price}/>}
        </div>
      </div>

      <FranchiseeEnquiry
        className="flex justify-center rounded-button py-3 mt-8 w-full border-2 border-brand-light-blue text-center text-brand-blue font-semibold"/>

      {franchisee.new && <Tag blueTag={true} className="absolute right-0 top-0" title={'New'}/>}
    </div>
  );
};

