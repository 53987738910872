import React from 'react';
import {SocialLinks} from '../Buttons/SocialLinks';
import {Manager} from '../Types';

export const PropertyManager = ({manager}: { manager: Manager }) => (
    <div className="flex flex-col text-brand-blue">
        <img src={manager.profileImage} alt={'Profile picture of ' + manager.name} className="w-auto h-96 object-cover rounded-2.5xl"/>
        <h3 className="text-2.5xl mt-7">{manager.name}</h3>
        <h4 className="text-lg">{manager.area}</h4>
        <p className="mt-5">{manager.blurb}</p>
        <div className="text-base flex flex-col space-y-4 mt-4">
            <p className="text-base mt-0 flex flex-col">
                <span className="font-bold text-base">Mobile</span>
                <a href={'tel:' + manager.phone} className="text-base lg:text-brand-blue no-underline font-normal">{manager.phone}</a>
            </p>

            <p className="text-base mt-0 flex flex-col">
                <span className="font-bold text-base">Email</span>
                <a href={'mailto:' + manager.email} className="text-base lg:text-brand-blue no-underline font-normal">{manager.email}</a>
            </p>
        </div>

        <SocialLinks className='mt-4' linkedIn={manager.linkedIn} facebook={manager.facebook}/>
        <a className="btn-blue mt-8 mr-auto" href={manager.link}>
            Meet {manager.name}
        </a>
    </div>
);

