import {SelectItem} from '../Types';

export type SelectItemResponse = {
  data: SelectItem[];
  selected: undefined | string;
};
export const getSelectItem = (url: string): { request: Promise<SelectItemResponse>, abortController?: AbortController } => {
  const abortController = new AbortController();
  const signal = abortController.signal;

  const request = fetch(url, {signal})
    .then((r): Promise<SelectItemResponse> => r.json());

  return {request, abortController};
};

export const setRegionAPI = (region: string = ''): { abortController?: AbortController } => {
  if (region === '') return {};
  const abortController = new AbortController();
  const signal = abortController.signal;

  fetch(`/api/set-region/${region}`, {signal})
    .then((r) => !r.ok && console.error(r))

  return {abortController};
};

export const setDistrictAPI = (region: string = ''): { abortController?: AbortController } => {
  if (region === '') return {};
  const abortController = new AbortController();
  const signal = abortController.signal;

  fetch(`/api/set-district/${region}`, {signal})
    .then((r) => !r.ok && console.error(r));

  return {abortController};
};
