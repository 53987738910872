'use strict';
import cls from 'classnames';
import React, {useEffect, useMemo, useState} from 'react';
import * as Collapsible from '@radix-ui/react-collapsible';

const collapsibleSplit = 5;

const ListItem = ({item}: { item: string }) => (
    <li className="flex items-start space-x-2">
        <svg width="29" height="30" viewBox="0 0 29 30" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g opacity="0.3">
                <path
                    d="M26.1763 4.47691C26.5668 4.08639 27.2 4.08639 27.5905 4.47691C27.9811 4.86744 27.9811 5.5006 27.5905 5.89113L14.1623 19.3194C13.7718 19.7099 13.1386 19.7099 12.7481 19.3194C12.3575 18.9288 12.3575 18.2957 12.7481 17.9052L26.1763 4.47691Z"
                    fill="#005395"/>
                <path
                    d="M14.1623 17.9056C14.5528 18.2961 14.5528 18.9293 14.1623 19.3198C13.7718 19.7103 13.1386 19.7103 12.7481 19.3198L8.97798 15.5497C8.58746 15.1592 8.58746 14.526 8.97798 14.1355C9.36851 13.745 10.0017 13.745 10.3922 14.1355L14.1623 17.9056Z"
                    fill="#005395"/>
                <circle cx="13" cy="16.6367" r="12" stroke="#005395" strokeWidth="2"/>
            </g>
        </svg>
        <span className="w-fit">{item}</span>
    </li>
)
export const MobileListBlock = ({listItems}: { listItems: string[] }) => {
    const [open, setOpen] = useState(false);
    const alwaysOpenItems = listItems.slice(0, collapsibleSplit);
    const collapsibleItems = listItems.slice(collapsibleSplit);

    return (
          <Collapsible.Root open={open} onOpenChange={setOpen}>
            <ul className="text-brand-blue text-lg space-y-6 mx-auto">
                {
                    (alwaysOpenItems).map((item, index) => (
                        <ListItem item={item} key={index}/>
                    ))
                }
                <Collapsible.Content className="space-y-6 overflow-hidden data-[state=open]:animate-slideDown data-[state=closed]:animate-slideUp">
                {
                    (collapsibleItems).map((item, index) => (
                        <ListItem item={item} key={index}/>
                    ))
                }
                </Collapsible.Content>
            </ul>
          <Collapsible.Trigger className="flex text-brand-red  items-center mt-16 mx-auto">
              <span className="text-brand-red font-semibold">{open ? 'Show less' : 'Show more'}</span>
              <svg width="40" className={cls('ease-in-out duration-7=300', open ? 'rotate-180' : 'rotate-0')} height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <circle cx="20" cy="20" r="14" className="stroke-brand-red" strokeWidth="2"/>
                  <path d="M23.364 18.2933C23.7545 18.6838 23.7545 19.317 23.364 19.7075L20.5356 22.5359C20.145 22.9265 19.5119 22.9265 19.1213 22.5359C18.7308 22.1454 18.7308 21.5123 19.1213 21.1217L21.9498 18.2933C22.3403 17.9028 22.9735 17.9028 23.364 18.2933Z" fill="currentColor"/>
                  <path d="M20.5356 22.5359C20.145 22.9265 19.5119 22.9265 19.1213 22.5359L16.2929 19.7071C15.9024 19.3166 15.9024 18.6834 16.2929 18.2929C16.6834 17.9024 17.3166 17.9024 17.7071 18.2929L20.5356 21.1213C20.9261 21.5118 20.9261 22.1454 20.5356 22.5359Z" fill="currentColor"/>
              </svg>
          </Collapsible.Trigger>
        </Collapsible.Root>
    )
}
