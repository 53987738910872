'use strict';
import React from 'react';


export const PlayButton = ({className} : {className?:string}) =>
    <div className={className}>
        <div className="relative group/play-button isolate rounded-full grid place-items-center aspect-square lg:w-17.5 w-10">
            <div className='absolute inset-0 bg-brand-red rounded-full group-hover/play-button:scale-125 ease-in-out duration-300'></div>
            <img src="/assets/frontend/play.svg" className="relative z-10 lg:w-auto w-2 h-auto"/>
        </div>
    </div>
