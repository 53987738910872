'use strict';
import cls from 'classnames';
import React, {useEffect, useState} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {SecondaryArrowButton} from '../Buttons/SecondaryArrowButton';

interface Testimonial {
  testimonial: string;
  endorser: string;
  subTitle: string;
  testimonialMedia: {
    src: string;
    alt: string;
  };
}

const responsive = {
  superLargeDesktop: {
    breakpoint: {max: 4000, min: 3000},
    items: 1,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 1,
  },
  tablet: {
    breakpoint: {max: 1024, min: 464},
    items: 1,
  },
  mobile: {
    breakpoint: {max: 464, min: 0},
    items: 1,
  },
};

const Buttons = ({next, previous, className}: { next: () => void, previous: () => void, className: string }) => (
  <div className={cls('lg:justify-start justify-between  pointer-events-none lg:w-auto w-full lg:space-x-3.5 z-60', className)}>
    <SecondaryArrowButton onClick={() => previous()}
                          className="rotate-180 bg-red-200 sm:ml-0 -ml-6 z-30 pointer-events-auto"/>
    <SecondaryArrowButton onClick={() => next()}
                          className=" sm:mr-16 mr-10 z-30 pointer-events-auto"/>
  </div>
);

const Testimonial = ({testimonial, title, controls}: {
  testimonial: Testimonial,
  title: string,
  controls: { next: () => void, previous: () => void }
}) => (
  <div className="flex items-stretch p-lg:flex-row flex-col xl:space-x-36 sm:space-x-12 my-7 lg:space-y-0 sm:space-y-12 space-y-2 z-50">
    <div className="w-full relative">
      <div className="lg:mx-0 mx-auto h-full lg:w-full lg:max-w-fit max-w-lg flex flex-col">
        <div className="lg:flex hidden items-center space-x-3">
          <svg className="mb-4" width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
            <rect x="12.8127" y="28" width="20.5912" height="3.08868" rx="1.54434" transform="rotate(-128.48 12.8127 28)" fill="#005395"/>
            <rect x="13.2129" y="18.0412" width="20.5912" height="3.08868" rx="1.54434" transform="rotate(-128.48 13.2129 18.0412)" fill="#EF3C43"/>
          </svg>
          <h3 className="text-base text-brand-blue text-center leading-slim font-semibold">
            {title}
          </h3>
        </div>
        <h4 className="text-brand-blue sm:text-2.5xl text-lg sm:my-4 mt-9 lg:mr-10 sm:mx-0 mx-15 leading-normal ">{testimonial.testimonial}</h4>
        <div className="sm:text-md text-sm sm:mx-0 mx-15 text-brand-blue sm:mt-0 mt-4 sm:w-full w-auto mb-10">
          <p className="">{testimonial.endorser}</p>
          <p className="">{testimonial.subTitle}</p>
        </div>
        <Buttons className="lg:flex hidden mt-auto" next={controls?.next} previous={controls?.previous}/>
      </div>
    </div>
  </div>
);


const CustomButtonGroup = ({next, previous, setControls}: any) => {
  useEffect(() => {
    setControls({next, previous});
  }, []);
  return null;
};

const CustomDot = ({onClick, active}: any) => (
  <button
    className={cls(active ? 'bg-brand-red' : 'bg-brand-blue', 'w-2.5 h-2.5 rounded-full md:hidden block')}
    onClick={() => onClick()}
  />
);


export const TestimonialsBlock = ({testimonials, title}: { testimonials: Testimonial[], title: string }) => {
  const [controls, setControls] = useState();

  return (
    <div className="h-full md:mx-auto lg:max-w-screen-2xl w-full my-7">
      <div className="lg:hidden flex items-center space-x-3 mx-10">
        <svg className="mb-4" width="16" height="28" viewBox="0 0 16 28" fill="none" xmlns="http://www.w3.org/2000/svg">
          <rect x="12.8127" y="28" width="20.5912" height="3.08868" rx="1.54434" transform="rotate(-128.48 12.8127 28)" fill="#005395"/>
          <rect x="13.2129" y="18.0412" width="20.5912" height="3.08868" rx="1.54434" transform="rotate(-128.48 13.2129 18.0412)" fill="#EF3C43"/>
        </svg>
        <h3 className="text-base text-brand-blue text-center leading-slim font-semibold">
          {title}
        </h3>
      </div>
      <div className="h-full lg:mx-auto relative lg:w-3/5 sm:ml-0 bg-brand-off-white rounded-box p-8">
        <Carousel
          additionalTransfrom={0}
          arrows={false}
          autoPlaySpeed={3000}
          className=""
          customButtonGroup={<CustomButtonGroup setControls={setControls}/>}
          containerClass="mx-auto relative h-full"
          draggable
          focusOnSelect={false}
          infinite={true}
          itemClass={cls('h-full')}
          keyBoardControl
          minimumTouchDrag={80}
          pauseOnHover
          renderArrowsWhenDisabled={false}
          renderButtonGroupOutside={true}
          renderDotsOutside={false}
          responsive={responsive}
          rewind={false}
          rewindWithAnimation={false}
          rtl={false}
          shouldResetAutoplay
          showDots={true}
          customDot={<CustomDot/>}
          dotListClass="md:space-x-3"
          partialVisible={true}
          sliderClass="h-full"
          slidesToSlide={1}
          swipeable
        >
          {testimonials.map((testimonial: Testimonial, index: number) => (
            // @ts-ignore
            <Testimonial key={index} testimonial={testimonial} title={title} controls={controls}/>
          ))}
        </Carousel>
      </div>
    </div>
  );
};


