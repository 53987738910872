'use strict';
import cls from 'classnames';
import React, {useEffect, useState} from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {SecondaryArrowButton} from '../Buttons/SecondaryArrowButton';
import {Franchise, Franchisee} from '../Partials/Franchisee';
import {getFranchisees} from '../Services/franchise';
import {SelectItem} from '../Types';
import {SelectRegion} from './SelectRegion';

const responsive = {
  superLargeDesktop: {
    breakpoint: {max: 4000, min: 3000},
    items: 3,
    slidesToSlide: 4,
  },
  desktop: {
    breakpoint: {max: 3000, min: 1024},
    items: 3,
    slidesToSlide: 4,
  },
  tablet: {
    breakpoint: {max: 1024, min: 767},
    items: 2,
    slidesToSlide: 2,
  },
  mobile: {
    breakpoint: {max: 767, min: 0},
    items: 1,
    slidesToSlide: 1,
  },
};

const CustomButtonGroup = ({next, previous, carouselState}: any) => carouselState.slidesToShow < carouselState.totalItems && (
  // 2rem on top to combat padding bottom 16 from carousel wrapper
  <div
    className="pointer-events-none absolute lg:top-[calc(50%-2rem)] sm:top-auto top-[calc(50%-2rem)] lg:bottom-auto md:-bottom-0 max-w-screen-2xl flex justify-between w-full lg:-translate-y-1/2 sm:translate-y-auto -translate-y-1/2 z-20">
    <SecondaryArrowButton onClick={() => previous()}
                          className={cls('pointer-events-auto rotate-180 lg:-ml-8 bg-red-200 select-none z-20', carouselState.currentSlide === 0 ? 'opacity-0 pointer-events-none ' : 'opacity-100')}/>
    <SecondaryArrowButton onClick={() => next()}
                          className={cls('pointer-events-auto 2xl:-mr-8 xl:-mr-4 lg:-mr-5 select-none z-20')}/>
  </div>
);

const CustomDot = ({onClick, active}: any) => (
  <button
    className={cls(active ? 'bg-brand-blue' : 'bg-brand-light-blue', 'w-2.5 h-2.5 rounded-full md:block hidden')}
    onClick={() => onClick()}
  />
);

const types = [
  '',
  'PukekoProven',
  'PukekoPioneer',
];


export const FranchiseeBlock = ({franchiseeContentID}: { franchiseeContentID?: string }) => {
  const [region, setRegion] = useState<SelectItem | null>(null);
  const [type, setType] = useState<string>('');
  const [franchisees, setFranchisees] = React.useState<Franchise[]>([]);
  useEffect(() => {
    const {request, abortController} = getFranchisees([region?.id ? 'region=' + region?.id : '', type ? 'type=' + type : '']);
    request.then(({data}) => {
      if (!abortController?.signal.aborted) {
        if (data?.length > 0) {
          setFranchisees(data);
        } else {
          setFranchisees([]);
        }
      }
    });
    return () => abortController?.abort();
  }, [region, type]);

  return (
    <div className="md:overflow-hidden lg:-mt-36 lg:pt-16">
      <div className="flex flex-col relative h-full mx-auto lg:max-w-screen-2xl sm:px-10 px-5 md:w-auto">
        <div className="flex md:flex-row flex-col md:space-y-0 space-y-6 justify-between items-center mb-8 lg:pt-36 sm:pt-16">
          <SelectRegion regionChanged={setRegion}/>
          <div className="flex sm:flex-row flex-col items-center lg:space-x-8 sm:space-x-6 space-x-0">
            <p className="text-brand-blue font-semibold">Show Me</p>
            <div className="flex items-center lg:space-x-8 sm:space-x-6 space-x-4 sm:text-base text-xs sm:mt-0 mt-3">
              <button onClick={() => setType(types[0])}
                      className={cls('pb-1', type === types[0] ? 'border-b border-brand-red text-brand-red' : 'text-brand-blue')}>
                All listings
              </button>
              <button onClick={() => setType(types[1])}
                      className={cls('pb-1', type === types[1] ? 'border-b border-brand-red text-brand-red' : 'text-brand-blue')}>
                Pukeko Proven
              </button>
              <button onClick={() => setType(types[2])}
                      className={cls('pb-1', type === types[2] ? 'border-b border-brand-red text-brand-red' : 'text-brand-blue')}>
                Pukeko Pioneer
              </button>
            </div>
          </div>
        </div>
        <div className="w-full relative">
          <Carousel
            additionalTransfrom={0}
            arrows={false}
            autoPlaySpeed={3000}
            className=""
            customButtonGroup={<CustomButtonGroup/>}
            containerClass="mx-auto pb-16"
            draggable
            focusOnSelect={false}
            itemClass=" px-2.5"
            keyBoardControl
            minimumTouchDrag={80}
            partialVisible
            pauseOnHover
            renderArrowsWhenDisabled={false}
            renderButtonGroupOutside={true}
            renderDotsOutside={false}
            responsive={responsive}
            rewind={false}
            rewindWithAnimation={false}
            rtl={false}
            shouldResetAutoplay
            showDots={true}
            customDot={<CustomDot/>}
            dotListClass="md:space-x-3"
            sliderClass=""
            slidesToSlide={1}
            swipeable
          >
            {
              franchisees.map((franchisee, index) => (
                  <Franchisee franchisee={franchisee} key={index} franchiseeContentID={franchiseeContentID}/>
                ),
              )
            }
          </Carousel>
        </div>
      </div>

    </div>
  );
};
