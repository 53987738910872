'use strict';
import * as Dialog from '@radix-ui/react-dialog';
import React, {useEffect, useRef, useState} from 'react';


const FormLoader = ({id, crossOrigin, src}: { id: string, crossOrigin: string, src: string}) => {
  const formContainerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (formContainerRef.current && !document.querySelector('.' + id)) {
      const script = document.createElement('script');
      script.src = src;
      script.async = true;
      script.crossOrigin = crossOrigin;
      script.id = id;

      formContainerRef.current.appendChild(script);

      return () => {
        script.remove();
      };
    }
  }, [src]);

  return (
    <div className="form-container" ref={formContainerRef}>
    </div>
  );
};

interface FranchiseeEnquiryProps {
  className?: string
}

export const FranchiseeEnquiry = (
  {
    className,
  }: FranchiseeEnquiryProps) => {
  const [open, setOpen] = useState(false);

  return (
    <Dialog.Root open={open} onOpenChange={setOpen}>
      <Dialog.Trigger asChild>
        <button className={className}>
          Enquire
        </button>
      </Dialog.Trigger>
      <Dialog.Portal>
        <Dialog.Overlay className="bg-black opacity-70 data-[state=open]:animate-overlayShow fixed z-50 inset-0"/>
        <Dialog.Content
          className="data-[state=open]:animate-contentShow sm:h-auto max-h-[90vh] fixed z-50 top-[50%] left-[50%] md:w-auto w-[95%] translate-x-[-50%] translate-y-[-50%] focus:outline-none">
          <div className="sm:w-128 relative max-h-[90vh] rounded-xl overflow-auto">
            <div className="flex flex-col sm:gap-y-5 gap-y-2">
              <FormLoader
                src="https://pukekorentalmanagers.freshsales.io/web_forms/30368dc96a22e5d5d577a97a5f9517c5544239aa7929b9d4eebdb37a7573b567/form.js"
                crossOrigin="anonymous" id="fs_30368dc96a22e5d5d577a97a5f9517c5544239aa7929b9d4eebdb37a7573b567"/>
            </div>
          </div>
          <button
            className="text-white ease-in-out transition duration-300 hover:text-brand-red absolute top-6 right-6 inline-flex w-auto appearance-none items-center justify-center rounded-full"
            aria-label="Close"
            onClick={() => setOpen(false)}
          >
            <svg xmlns="http://www.w3.org/2000/svg" width="40" height="40" viewBox="0 0 40 40" fill="none">
              <circle cx="20" cy="20" r="14" fill="white" stroke="#005395" strokeWidth="2"/>
              <path
                d="M23.364 16.2933C23.7545 16.6838 23.7545 17.317 23.364 17.7075L20.5356 20.5359C20.145 20.9265 19.5119 20.9265 19.1213 20.5359C18.7308 20.1454 18.7308 19.5123 19.1213 19.1217L21.9498 16.2933C22.3403 15.9028 22.9735 15.9028 23.364 16.2933Z"
                fill="#005395"/>
              <path
                d="M20.5356 20.5359C20.145 20.9265 19.5119 20.9265 19.1213 20.5359L16.2929 17.7071C15.9024 17.3166 15.9024 16.6834 16.2929 16.2929C16.6834 15.9024 17.3166 15.9024 17.7071 16.2929L20.5356 19.1213C20.9261 19.5118 20.9261 20.1454 20.5356 20.5359Z"
                fill="#005395"/>
              <path
                d="M16.2932 23.5355C15.9026 23.145 15.9026 22.5118 16.2932 22.1213L19.1216 19.2929C19.5121 18.9024 20.1453 18.9024 20.5358 19.2929C20.9264 19.6834 20.9264 20.3166 20.5358 20.7071L17.7074 23.5355C17.3169 23.9261 16.6837 23.9261 16.2932 23.5355Z"
                fill="#005395"/>
              <path
                d="M19.1216 19.2929C19.5121 18.9024 20.1453 18.9024 20.5358 19.2929L23.3643 22.1218C23.7548 22.5123 23.7548 23.1454 23.3643 23.536C22.9738 23.9265 22.3406 23.9265 21.9501 23.536L19.1216 20.7076C18.7311 20.317 18.7311 19.6834 19.1216 19.2929Z"
                fill="#005395"/>
            </svg>
          </button>
        </Dialog.Content>
      </Dialog.Portal>
    </Dialog.Root>
  );
};
