import {MoreInfoSelectOptions, EnquiryForm, PropertyManagersResponse, RequestWithAbortController} from '../Types';

export const submitMessageRequest = (form: Omit<EnquiryForm, 'id'>): RequestWithAbortController<{ success: true, redirect: string }> => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const body = new FormData();
    body.append('Name', form.name);
    body.append('Email', form.email);
    body.append('Phone', form.phone);
    body.append('MoreInfo', form.moreInfo);
    body.append('Message', form.message ?? '');
    body.append('Recaptcha', form.recaptcha ?? '');
    body.append('ContextID', form.contextID ?? '');

    const request = fetch(form.route, {
        method: 'POST',
        signal,
        body,
    });

    return {request ,abortController};
};

export const getMoreInfoOptions = (): { request: Promise<MoreInfoSelectOptions[]>, abortController?: AbortController } => {
    const abortController = new AbortController();
    const signal = abortController.signal;

    const request = fetch('/property-manager/moreInfoOptions', {signal})
        .then(r =>  r.json());

    return {request, abortController};
};

export const getPropertyManagers = (params?: string): {request: Promise<PropertyManagersResponse>, abortController?: AbortController} => {

    const abortController = new AbortController();
    const signal = abortController.signal;

    const request = fetch(`/property-manager/property-managers?` + (params ?  '&'+ params : ''), {signal})
        .then((r): Promise<PropertyManagersResponse> => r.json())

    return {request, abortController};
};
