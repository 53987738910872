'use strict';
import cls from 'classnames';
import React from 'react';


export function SecondaryArrowButton({className, onClick, disabled = false}: { className?: string, onClick?: () => void, disabled?: boolean }) {
    return (
        <button disabled={disabled} onClick={onClick} className={cls(className, 'bg-white ListingButton hover:bg-brand-red hover:fill-white rounded-full border-2 border-brand-red lg:p-6 p-2 lg:px-arrow-button px-3')}>
            <svg xmlns="http://www.w3.org/2000/svg" className='stroke-brand-red fill-brand-red lg:block hidden' width="8" height="12" viewBox="0 0 8 12">
                <rect className="fill-brand-red" x="1.5" width="8.48537" height="2.12134" rx="1" transform="rotate(45 1.5 0)"/>
                <rect className="fill-brand-red" x="7.50006" y="5.99994" width="8.48537" height="2.12134" rx="1" transform="rotate(135 7.50006 5.99994)"/>
            </svg>
            <svg xmlns="http://www.w3.org/2000/svg" className='stroke-brand-red fill-brand-red lg:hidden block' width="5" height="12" viewBox="0 0 8 12">
                <rect className="" x="1.5" width="8.48537" height="2.12134" rx="1" transform="rotate(45 1.5 0)"/>
                <rect className="" x="7.50006" y="5.99994" width="8.48537" height="2.12134" rx="1" transform="rotate(135 7.50006 5.99994)"/>
            </svg>
        </button>
    )
}
