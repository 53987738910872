'use strict';
import cls from 'classnames';
import React from 'react';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import {SecondaryArrowButton} from '../Buttons/SecondaryArrowButton';

const responsive = {
    superLargeDesktop: {
        breakpoint: {max: 4000, min: 3000},
        items: 5,
        slidesToSlide: 4,
        partialVisibilityGutter: 40,
    },
    desktop: {
        breakpoint: {max: 3000, min: 1024},
        items: 5,
        partialVisibilityGutter: -20,
        slidesToSlide: 4,
    },
    tablet: {
        breakpoint: {max: 1024, min: 767},
        items: 2,
        partialVisibilityGutter: -10,
        slidesToSlide: 2,
    },
    mobile: {
        breakpoint: {max: 767, min: 0},
        items: 1,
        slidesToSlide: 1,
    },
};

function stripHtmlTags(html: string | null) {
  return html?.replace(/<[^>]*>/g, '');
}

type Article = {
  shortDescription: string,
  title: string,
  link: string,
  imageSource: string
}

const Article = ({article}: { article: Article }) => (
  <a href={article.link} className="md:h-listing h-auto flex flex-col w-auto sm:mx-0 mx-10 group">
    <div className="overflow-hidden rounded-box">
      {
        article.imageSource ?
          <img src={article.imageSource} alt="rental listing" className="w-full md:h-listing-image sm:h-64 h-auto object-cover object-center group-hover:scale-110 transition-transform ease-in-out duration-300"/>
          :
          <div className="w-full md:h-listing-image h-auto bg-brand-off-white"></div>
      }
    </div>
    <h4 className="text-base text-brand-blue font-semibold mt-4">{article.title}</h4>
    <p className="text-xs text-brand-blue line-clamp-4">{stripHtmlTags(article.shortDescription)}</p>
  </a>
);

const CustomButtonGroup = ({next, previous, carouselState}: any) => carouselState.slidesToShow < carouselState.totalItems && (
    <div className="pointer-events-none absolute lg:top-1/2 sm:top-auto top-1/2 lg:bottom-auto md:-bottom-0 max-w-screen-2xl flex justify-between w-full lg:-translate-y-1/2 sm:translate-y-auto -translate-y-1/2 z-20">
        <SecondaryArrowButton onClick={() => previous()}
                              className={cls('pointer-events-auto rotate-180 lg:-ml-8 bg-red-200 select-none z-20', carouselState.currentSlide === 0 ? 'opacity-0 pointer-events-none ' : 'opacity-100')}/>
        <SecondaryArrowButton onClick={() => next()}
                              className={cls('pointer-events-auto lg:-mr-8 select-none z-20')}/>
    </div>
);

const CustomDot = ({onClick, active}: any) => (
    <button
        className={cls(active ? 'bg-brand-red' : 'bg-brand-blue', 'w-2.5 h-2.5 rounded-full md:hidden block ')}
        onClick={() => onClick()}
    />
);


export const RelatedArticlesBlock = ({articles}: { articles: Article[] }) => (
    <div className="md:overflow-hidden md:h-listing sm:px-10 px-5 md:w-auto">
        <div className="block relative h-full 2xl:mx-auto lg:max-w-screen-2xl sm:px-10 px-5">
            <div style={{
                width: 'calc(20% + 1.25rem)',
                left: 'calc(100% - 1.25rem)',

            }} className="md:block hidden bg-gradient-to-r from-transparent to-white to-70% absolute h-full z-10"/>
            <div className="lg:w-carousel w-full md:absolute left-0  sm:px-10 px-5">
                <Carousel
                    additionalTransfrom={0}
                    arrows={false}
                    autoPlaySpeed={3000}
                    className=""
                    customButtonGroup={<CustomButtonGroup/>}
                    containerClass="mx-auto relative"
                    draggable
                    focusOnSelect={false}
                    itemClass="sm:pr-5"
                    keyBoardControl
                    minimumTouchDrag={80}
                    partialVisible
                    pauseOnHover
                    renderArrowsWhenDisabled={false}
                    renderButtonGroupOutside={true}
                    renderDotsOutside={false}
                    responsive={responsive}
                    rewind={false}
                    rewindWithAnimation={false}
                    rtl={false}
                    shouldResetAutoplay
                    showDots={true}
                    customDot={<CustomDot/>}
                    dotListClass="md:space-x-3"
                    sliderClass=""
                    slidesToSlide={1}
                    swipeable
                >
                    {
                        articles.map((article, index) => (
                                <Article article={article} key={index}/>
                            ),
                        )
                    }
                </Carousel>
            </div>
        </div>
    </div>
);
