'use strict';
import React from 'react';
import VideoModal from '../PageSections/VideoModal';
import {PlayButton} from './PlayButton';

export const VideoPlayButton = ({video, youtube}: { video: string, youtube: boolean }) => (
    <VideoModal src={video} youtube={youtube}>
        <button className="relative">
            <PlayButton/>
        </button>
    </VideoModal>
);

