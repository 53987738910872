import {Listing} from '../Types';

export type PropertiesPaginationPage = {
    currentBool: boolean;
    link: string | null;
    pageNumber: number | null;
};
export type PropertiesPagination = {
    moreThanOnePage: boolean;
    nextLink: string | null;
    notFirstPage: boolean;
    notLastPage: boolean;
    paginationPages: PropertiesPaginationPage[];
    prevLink: string | null;
};

export type PropertiesResponse = {
    data: Listing[];
    pagination: PropertiesPagination;
};

export const getProperties = (page: number, perPage = 20, params?: string): {request: Promise<PropertiesResponse>, abortController?: AbortController} => {

    const abortController = new AbortController();
    const signal = abortController.signal;

    const request = fetch(`/api/properties?start=${page * perPage}&perPage=${perPage}` + (params ?  '&'+params : ''), {signal})
        .then((r): Promise<PropertiesResponse> => r.json())

    return {request, abortController};
};
