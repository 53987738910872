import {Franchise} from '../Partials/Franchisee';

export type FranchiseeResponse = {
    data: Franchise[];
};

export const getFranchisees = (params?: string[]): { request: Promise<FranchiseeResponse>, abortController?: AbortController } => {

    const abortController = new AbortController();
    const signal = abortController.signal;

    const request = fetch(`/franchisees/api/list` + (params ? ('?' + params?.filter(Boolean).join('&')) : ''), {signal})
        .then((r): Promise<FranchiseeResponse> => r.json());

    return {request, abortController};
};
