'use strict';
import cls from 'classnames';
import {AnimatePresence, LayoutGroup, motion} from 'framer-motion';
import React, {useEffect, useMemo, useRef, useState} from 'react';
import {SecondaryArrowButton} from '../Buttons/SecondaryArrowButton';
import 'swiper/swiper-bundle.css';

type Image = {
  src: string;
  title: string
}

const CustomButtonGroup = ({next, previous, carouselState}: any) => {
  const [disabled, setDisabled] = useState(false);
  useEffect(() => {
    if (!disabled) return;
    const timer = setTimeout(() => {
      setDisabled(false);
    }, 550);
    return () => clearTimeout(timer);
  }, [disabled]);

  return (
    <div className="absolute pointer-events-none inset-0 mx-auto lg:max-w-screen-2xl sm:px-10 px-5 md:w-auto z-20">
      <div
        className="pointer-events-none absolute top-1/2 lg:bottom-auto max-w-screen-2xl flex justify-between w-full lg:-translate-y-1/2 sm:translate-y-auto -translate-y-1/2">
        <SecondaryArrowButton disabled={disabled} onClick={() => {
          previous();
          setDisabled(true);
        }}
                              className={cls('pointer-events-auto rotate-180 bg-red-200 select-none z-20', carouselState?.currentSlide === 0 ? 'opacity-0 pointer-events-none ' : 'opacity-100')}/>
        <SecondaryArrowButton disabled={disabled} onClick={() => {
          next();
          setDisabled(true);
        }}
                              className={cls('pointer-events-auto select-none z-20')}/>
      </div>
    </div>
  );
};

export const PropertyImageCarousel = ({images: propImages}: {
  images: Image[]
}) => {
  const swiperRef = useRef(null);
  const viewportRef = useRef(null);
  const [indexOffset, setIndexOffset] = useState(0);

  const images = useMemo(() => {
    let images = [...propImages];

    while (images.length < 10) {
      images = [...images, ...propImages];
    }

    return images?.map((image, index) => ({
      id: `image-${image?.src}-${index}`,
      ...image,
    }));
  }, [propImages]);

  const offsetImages = useMemo(() => {
    return [...images.slice(indexOffset), ...images.slice(0, indexOffset)];
  }, [indexOffset, images]);

  return (
    <>
      <div className="relative max-sm:hidden w-full md:overflow-hidden">
        <div className="relative flex gap-2 mx-auto 2xl:max-w-[1920px] sm:px-10 px-5 w-auto py-1">
          <div className="2xl:overflow-hidden w-full">
            <div ref={viewportRef}
                 className="relative [&_*]:shrink-0 flex gap-5 mx-auto lg:max-w-screen-xl sm:px-10 px-5 w-auto overflow-visible">
              <LayoutGroup id="property-image-carousel">
                {[...Array(5)]?.map((_, index) => (
                  <div key={index} className={cls('h-[41.5625rem]', index ? 'relative' : 'absolute -left-5 -translate-x-full')}>
                    <AnimatePresence initial={false} mode="popLayout">
                      <motion.div
                        key={offsetImages[index]?.id}
                        src={offsetImages[index]?.src}
                        className="h-full"
                        whileInView={{opacity: 1}}
                        viewport={{root: viewportRef}}
                        layoutId={`image-${offsetImages[index]?.id}`}
                        animate={{opacity: 0.5}}
                        exit={{opacity: 0}}
                      >
                        <img src={offsetImages[index]?.src} alt={offsetImages[index]?.title} className="h-full rounded-[0.625rem]"/>
                      </motion.div>
                    </AnimatePresence>
                  </div>
                ))}
              </LayoutGroup>
            </div>
          </div>
          <div className="max-2xl:hidden bg-gradient-to-r to-80% from-transparent to-white absolute -inset-y-1 w-48 -right-1 pointer-events-none"/>
          <div className="max-2xl:hidden bg-gradient-to-l to-80% from-transparent to-white absolute -inset-y-1 w-48 -left-1 pointer-events-none"/>
        </div>
        <CustomButtonGroup
          next={() => setIndexOffset(prev => (prev + 1) % (images?.length))}
          previous={() => setIndexOffset(prev => (prev - 1) % (images?.length))}/>
      </div>
      <div className="relative sm:hidden w-full md:overflow-hidden">
        <div className="relative w-full 2xl:max-w-[1920px] mx-auto isolate">
          <swiper-container ref={swiperRef} slides-per-view="auto" free-mode={true} loop={true} centered-slides={true}>
            {images?.map((image, index) => (
                <div className="swiper-slide px-5" key={index} style={{
                  width: 'auto',
                }}>
                  <img src={image.src} alt={image.title} className="h-56 sm:h-[41.5625rem]" style={{
                    borderRadius: '0.625rem'
                  }}/>
                </div>
              )
            )}
          </swiper-container>
          <CustomButtonGroup next={() => swiperRef?.current?.swiper?.slideNext?.()} previous={() => swiperRef?.current?.swiper?.slidePrev?.()}/>
        </div>
      </div>
    </>
  );
};
