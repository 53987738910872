'use strict';
import React from 'react';
import {Listbox} from '@headlessui/react';
import cls from 'classnames';
import {SelectItem} from '../Types';

const ChevronDown = () => (
    <svg width="12" height="9" className='fill-inherit' viewBox="0 0 12 9" fill="none" xmlns="http://www.w3.org/2000/svg">
        <g id="dropdown icon">
            <rect id="Rectangle 42" x="11.3135" y="2.37854" width="8" height="2" rx="1" transform="rotate(135 11.3135 2.37854)" fill="inherit"/>
            <rect id="Rectangle 43" x="5.65674" y="8.0354" width="8" height="2" rx="1" transform="rotate(-135 5.65674 8.0354)" fill="inherit"/>
        </g>
    </svg>
);
const SelectButton = ({open, selectedItem, red, title} : {open: boolean, selectedItem: SelectItem | null, red: boolean, title: string,className?: string}) => (
    <div className={cls('rounded-button sm:min-w-72 px-x-button pt-top-button pb-4 flex items-center xl:gap-18 justify-between border-2  ',
        red ? 'text-brand-red stroke-brand-red fill-brand-red' : 'text-brand-blue stroke-brand-blue fill-brand-blue',
        open ? 'border-transparent' : ('transition duration-150 ease-in-out hover:text-white hover:stroke-white hover:fill-white' +  (red ? ' border-brand-red hover:bg-brand-red ' : ' border-brand-blue hover:bg-brand-blue ')))}>
        <span className="text-lg leading-none font-semibold  ">{selectedItem ? selectedItem.name : title}</span>
        <div className={cls('transition-transform transform duration-300 ease-in-out', open ? 'rotate-180' : '')}>
            <ChevronDown/>
        </div>
    </div>
);
export function Select(
    {red = true, title, data, selectedItem, setSelectedItem, className} :
    {red?: boolean, title: string, data: SelectItem[] | [], selectedItem: SelectItem | null, setSelectedItem: (item: SelectItem | null) => void, className?:string}) {
    const onChange = (newSelectedPerson: any) => {
        setSelectedItem(newSelectedPerson);
    }

    return (
        <Listbox value={selectedItem} onChange={onChange} >
            {({open}) => (
                <div className={cls(open && 'shadow-home bg-white rounded-select z-20 relative',className)}>
                    <Listbox.Button>
                        <SelectButton open={open} red={red} selectedItem={selectedItem} title={title}/>
                    </Listbox.Button>
                    {open && <div className="absolute left-0 right-0 top-0 bg-white shadow-home rounded-select z-10 border-transparent border-2">
                        <div className='flex items-center sm:gap-4 gap-2 px-x-button pt-top-button justify-between'>
                            <span className="text-lg leading-none font-semibold text-brand-red">{selectedItem ? selectedItem?.name : title}</span>
                            <div className={cls('transition-transform transform duration-300 ease-in-out stroke-brand-red fill-brand-red', open ? 'rotate-180' : '')}>
                                <ChevronDown/>
                            </div>
                        </div>
                        <Listbox.Options>
                            <div className='space-y-dropdown mt-dropdown max-h-dropdown overflow-overlay pb-3'>
                                {data?.map((item) => (
                                    <Listbox.Option
                                        key={item.id}
                                        value={item}
                                    >
                                        <button className={cls('flex gap-4 items-center transition duration-150 ease-in-out tracking-slim px-x-button', selectedItem?.name === item.name ? 'text-brand-red' : 'text-brand-blue hover:text-brand-red')}>
                                            <span className="text-left">{item.name}</span>
                                            {selectedItem?.name === item.name && (
                                                <svg xmlns="http://www.w3.org/2000/svg" width="16" className="ml-auto" height="13" viewBox="0 0 16 13" fill="none">
                                                    <path d="M13.5375 1.45125C13.928 1.06072 14.5612 1.06072 14.9517 1.45125C15.3423 1.84177 15.3423 2.47494 14.9517 2.86546L5.77791 12.0393C5.38739 12.4298 4.75422 12.4298 4.3637 12.0393C3.97318 11.6488 3.97318 11.0156 4.3637 10.6251L13.5375 1.45125Z" fill="#EF3C43"/>
                                                    <path d="M5.77873 10.6132C6.16926 11.0037 6.16926 11.6369 5.77873 12.0274C5.38821 12.4179 4.75504 12.4179 4.36452 12.0274L0.875657 8.53856C0.485133 8.14803 0.485132 7.51487 0.875657 7.12434C1.26618 6.73382 1.89935 6.73382 2.28987 7.12434L5.77873 10.6132Z" fill="#EF3C43"/>
                                                </svg>
                                            )}
                                        </button>
                                    </Listbox.Option>
                                ))}
                            </div>
                        </Listbox.Options>
                    </div>}
                </div>
            )}
        </Listbox>
    );
}
