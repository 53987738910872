import React from 'react';
import {Listing} from '../Types';

export const Property = ({listing}: { listing: Listing }) => (
    <a href={listing.link} className="md:h-listing h-auto flex flex-col w-auto sm:mx-0 mx-10 group">
        <div className="overflow-hidden rounded-box">
            {
                listing.imageSource ?
                    <img src={listing.imageSource} alt="rental listing" className="w-full md:h-listing-image sm:h-64 h-auto object-cover object-center group-hover:scale-110 transition-transform ease-in-out duration-300"/>
                    :
                    <div className="w-full md:h-listing-image h-auto bg-brand-off-white"></div>
            }
        </div>
        <h4 className="text-base text-brand-blue font-semibold mt-4">{listing.number + ' ' + listing.street + ', ' + listing.suburb}</h4>
        <span className="text-xs text-brand-blue">{listing.region}</span>
        <div className="flex mt-5 gap-10">
            <div className="flex items-center gap-2.5">
                <img src="/assets/frontend/bedroom.svg" alt="bed" className="w-full mt-auto h-[23px]"/>
                <span className="text-sm text-brand-blue font-semibold">{listing.bedrooms}</span>
            </div>
            <div className="flex items-center gap-2.5">
                <img src="/assets/frontend/bathroom.svg" alt="bed" className="w-full h-7"/>
                <span className="text-sm text-brand-blue font-semibold">{listing.bathrooms}</span>
            </div>
        </div>
        <span className="text-base text-brand-blue font-semibold mt-4 mb-10">${listing.rentPerWeek} /week</span>

    </a>
);

