'use strict';
import cls from 'classnames';
import React, {useCallback, useEffect, useState} from 'react';
import {Select} from '../FormFields/Select';
import {getSelectItem, setDistrictAPI, setRegionAPI} from '../Services/regions';
import {SelectItem} from '../Types';

export function useDistricts(region: SelectItem | null, districtInitial: SelectItem | null = null, districtsInitial: SelectItem[] | [] = []) {

  const [district, setDistrict] = useState<SelectItem | null>(districtInitial);
  const [districts, setDistricts] = useState<SelectItem[] | []>(districtsInitial);


  useEffect(() => {
    if (district?.id) {
      const {abortController} = setDistrictAPI(`${district.id}`);

      return () => abortController?.abort();
    }
  }, [district]);

  useEffect(() => {
    setDistrict(null);
    if (region?.id) {
      const {request, abortController} = getSelectItem(`api/districts/${region.id}`);

      request.then(({data, selected}) => {
        if (!abortController?.signal.aborted) {
          setDistricts(data);
          if (selected) {
            setDistrict(data.find((item) => item.id.toString() === selected) || null);
          }
        }
      });
      return () => abortController?.abort();
    } else {
      setDistricts([]);
    }
  }, [region]);

  return {district: district, setDistrict: setDistrict, districts: districts};
}

export function SelectRegion({regionChanged, className, redirectURL = ''}: {
  className?: string,
  regionChanged?: (region: SelectItem | null) => void,
  redirectURL?: string
}) {
  const [regions, setRegions] = useState<SelectItem[] | []>([]);
  const [region, setRegion] = useState<SelectItem | null>(null);
  const [shouldRedirect, setShouldRedirect] = useState<boolean>(false);

  const setRegionAndRedirect = useCallback((item: SelectItem | null) => {
    setRegion(item);
    if(redirectURL){
      setShouldRedirect(true)
    }
  }, []);

  useEffect(() => {
    if (regionChanged) {
      regionChanged(region);
    }
  }, [region]);

  useEffect(() => {
    if (region?.id || region?.id === 0) {

      const {abortController} = setRegionAPI(`${region.id}`);

      if (shouldRedirect) {
        window.location.href = redirectURL;
      }
      return () => abortController?.abort();
    }

  }, [region]);

  useEffect(() => {
    const {request, abortController} = getSelectItem('api/regions/');

    request.then(({data, selected}) => {
      if (!abortController?.signal.aborted) {
        setRegions(data);
        if (selected) {
          setRegion(data.find((item) => item.id.toString() === selected) || null);
        }
      }
    });
    return () => abortController?.abort();
  }, []);


  return (
    <Select className={cls(className)} title="Select your region" data={regions} selectedItem={region}
            setSelectedItem={setRegionAndRedirect}/>
  );
}
