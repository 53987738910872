'use strict';

import cls from 'classnames';
import React, {useEffect, useState} from 'react';
import {Select} from '../FormFields/Select';
import {PropertyManager} from '../Partials/PropertyManager';
import {getPropertyManagers} from '../Services/propertyManagerForm';
import {Manager, SelectItem} from '../Types';
import {SelectRegion, useDistricts} from './SelectRegion';

export const PropertyManagerListingPage = () => {
    const [region, setRegion] = useState<SelectItem | null>(null);

    const [propertyManagers, setPropertyManagers] = useState<Manager[] | null>(null);

    const {district, setDistrict, districts} = useDistricts(region);

    useEffect(() => {
        const {request, abortController} = getPropertyManagers(`region=${region?.id ?? ''}&district=${district?.id ?? ''}`);

        request.then(({data}) => {
            if (!abortController?.signal.aborted) {
                setPropertyManagers(data);
            }
        }).catch((error) => {
                if (error.name !== 'AbortError') {
                    throw error;
                }
            },
        );
        return () => abortController?.abort();
    }, [region, district]);

    return (
        <div className={cls('h-full lg:max-w-screen-xl max-w-lg mx-auto sm:px-10 px-5 w-auto my-7 flex flex-col', (propertyManagers && propertyManagers.length > 0) ? 'mb-20' : '')}>
            <div className="flex flex-col space-y-25">
                <div className="flex sm:flex-row sm:space-y-0 space-y-4 flex-col sm:space-x-5 items-center justify-center">
                    <SelectRegion regionChanged={setRegion}/>
                    <Select className={cls('transition ease-in-out duration-300', region?.id ? 'opacity-100' : 'opacity-0 hidden')}
                            title="Select district" data={districts} setSelectedItem={setDistrict} selectedItem={district}/>
                </div>
                {(propertyManagers && propertyManagers?.length > 0) ?
                    <div className="grid xl:grid-cols-3 lg:grid-cols-2 grid-cols-1 gap-[5.31rem]">
                        {
                            propertyManagers.map((manager, index) => (
                                <PropertyManager manager={manager} key={index}/>
                            ))
                        }
                    </div>
                    :
                    <div className="flex flex-col items-center justify-center">
                        <h3 className="text-brand-blue sm:text-2.5xl text-lg mt-9 leading-normal text-center max-w-155 mx-auto">There are no results for your selection</h3>
                    </div>
                }
            </div>
        </div>
    );
};
